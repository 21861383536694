import { Suspense, useEffect, useCallback } from 'react'
import { ThemeProvider } from 'react-jss'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Routes from '@/router'
import Layout from '@/components/Layout'
import LoaderEngine from '@/components/LoaderEngine'
import Loader from '@/components/Loader'
import Portal from '@/components/__commons/Portal'
import * as routingActions from '@/actions/routing'
import * as optionsActions from '@/actions/language'
import * as navActions from '@/actions/nav'
import * as localeActions from '@/actions/locale'
import * as queryActions from '@/actions/mediaQuery'
import * as pageActions from '@/actions/page'
import useViewport from '@/hooks/useViewport'
import credits from '@/utils/credits'
import { theme } from '@/styles/style'

function App() {
  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { routes, currentLanguage } = useSelector((state) => ({
    routes: state.routing.routes || [],
    currentLanguage: state.locale.currentLanguage,
  }), shallowEqual)

  useViewport()

  const dispatch = useDispatch()
  const fetchRouting = useCallback(() => dispatch(routingActions.fetchRouting()), [dispatch])
  const fetchStrings = useCallback(() => dispatch(optionsActions.fetchStrings()), [dispatch])
  const getTerms = useCallback((tax, per_page) => dispatch(pageActions.getAllTerms(tax, per_page)), [dispatch])
  const fetchMenuLocations = useCallback(() => dispatch(navActions.fetchMenuLocations()), [dispatch])
  const fetchLocales = useCallback(() => dispatch(localeActions.fetchLocales()), [dispatch])
  const setLanguage = useCallback(() => dispatch(localeActions.setLanguage()), [dispatch])
  const setQuery = useCallback((data) => dispatch(queryActions.setQuery(data)), [dispatch])
  const setQueryKey = useCallback((data) => dispatch(queryActions.setQueryKey(data)), [dispatch])

  useEffect(() => {
    credits()
    setLanguage()
  }, [])

  useEffect(() => {
    if (currentLanguage !== null) {
      fetchRouting()
      fetchLocales()
      fetchStrings()
      fetchMenuLocations()
      getTerms('categories', 99)
      getTerms('_cta_typology', 99)
    }
  }, [currentLanguage])

  const setMQ = () => {
    if (window.matchMedia) {
      const medias = []
      const mediasInverse = []
      const mediaRange = []
      Object.entries(theme.media).map((item) => {
        const value = item[1]
        medias.push(value.replace('@media ', ''))
        return false
      })
      Object.entries(theme.mediaInverse).map((item) => {
        const value = item[1]
        mediasInverse.push(value.replace('@media ', ''))
        return false
      })
      Object.entries(theme.media).map((item, i) => {
        const key = item[0]
        mediaRange[key] = `${medias[i]} and ${mediasInverse[i]}`
        const setMedia = () => {
          if (medias[key].matches) {
            setQuery(key)
            setQueryKey(i)
          }
        }
        medias[key] = window.matchMedia(mediaRange[key])
        medias[key].addListener(() => {
          setMedia()
        })
        setMedia()
        return false
      })
    }
    return this
  }

  useEffect(() => {
    setMQ()
  }, [])

  return (
    <ThemeProvider theme={theme}>
      {routes.length > 0 ? (
        <Layout>
          <Suspense fallback={<div />}>
            <Routes routes={routes} />
          </Suspense>
        </Layout>
      ) : null}
      <Portal className="loader-root">
        <LoaderEngine>
          <Loader theme={theme} />
        </LoaderEngine>
      </Portal>
    </ThemeProvider>
  )
}

export default App
