/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useRef, useLayoutEffect, useCallback, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import injectSheet from 'react-jss'
import gsap from 'gsap'
import usePrevious from '@/hooks/usePrevious'
import Form from '@/components/__commons/Form'
import * as layerActions from '@/actions/layer'
import * as pageActions from '@/actions/page'
import style from './style'

function ModalForm({
  id,
  classes,
}) {
  const $root = useRef()
  const $popup = useRef()
  const [fields, setFields] = useState([])
  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { pathname, isModalOpen, strings } = useSelector((state) => ({
    pathname: state.router.location.pathname,
    strings: state.language.string,
    isModalOpen: state.layer.layers.some((layer) => layer.id === `modal-form-${id}` && layer.isOpen),
  }), shallowEqual)
  const prevPathname = usePrevious(pathname)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const closeLayer = useCallback(() => dispatch(layerActions.closeLayer({ id: `modal-form-${id}` })), [dispatch, id])
  const createLayerModel = useCallback(() => dispatch(layerActions.createLayerModel({ id: `modal-form-${id}` })), [dispatch, id])
  const fetchForm = useCallback((idx) => dispatch(pageActions.fetchForm(idx)), [dispatch])
  const saveConfiguration = useCallback((data) => dispatch(pageActions.saveConfiguration(data)), [dispatch])
  const sendContactForm = useCallback((data) => dispatch(pageActions.sendContactForm(id, data)), [dispatch, id])

  /*------------------------------
  Set Ready
  ------------------------------*/
  useEffect(() => {
    gsap.set($root.current, { autoAlpha: 0 })
    gsap.set($popup.current, { autoAlpha: 0 })
  }, [])

  useEffect(() => {
    async function fetchData() {
      createLayerModel()
      const data = await fetchForm(id)
      setFields(data.form)
    }
    if (id) fetchData()
  }, [id])

  /*------------------------------
  Manage Esc Key Down
  ------------------------------*/
  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Escape' && e.keyCode === 27 && isModalOpen) closeLayer()
  }, [isModalOpen])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [isModalOpen])

  /*------------------------------
  Toggle Interaction
  ------------------------------*/
  const toggleInteractionOnModal = useCallback((status) => {
    if (status && $root.current) $root.current.style.pointerEvents = status
  }, [])

  const openModal = useCallback(() => {
    gsap.timeline({ onStart: () => toggleInteractionOnModal('all') })
      .killTweensOf([$root.current, $popup.current])
      .to($root.current, {
        duration: 0.8,
        autoAlpha: 1,
        ease: 'power3.out',
      })
      .to($popup.current, {
        duration: 0.8,
        autoAlpha: 1,
        ease: 'power3.out',
      }, 0)
  }, [])

  const closeModal = useCallback(() => {
    gsap.timeline({ onStart: () => toggleInteractionOnModal('none') })
      .killTweensOf([$root.current, $popup.current])
      .to($popup.current, {
        duration: 0.8,
        autoAlpha: 0,
        ease: 'power3.out',
      })
      .to($root.current, {
        duration: 0.8,
        autoAlpha: 0,
        ease: 'power3.out',
      }, 0)
  }, [])

  /*------------------------------
  Close Nav when isOpen changed
  ------------------------------*/
  const prevIsModalOpen = usePrevious(isModalOpen)
  useEffect(() => {
    if (prevIsModalOpen !== undefined && isModalOpen) openModal()
    if (prevIsModalOpen !== undefined && !isModalOpen) closeModal()
  }, [isModalOpen])

  /*------------------------------
  Close Nav on change page
  ------------------------------*/
  useLayoutEffect(() => {
    if (
      prevPathname !== undefined
      && prevPathname !== pathname
    ) {
      setTimeout(() => {
        closeModal()
      }, 500)
    }
  }, [pathname])

  return (
    <div
      className={classes.root}
      role="dialog"
      aria-modal="true"
      ref={$root}
    >
      <div
        className={classes.overlay}
        onClick={closeLayer}
      />
      <div
        className={classes.popup}
        ref={$popup}
      >
        <div className={classes.container}>
          <button
            className={classes.close}
            onClick={closeLayer}
            aria-label="close-modal"
          >
            <svg><use xlinkHref="#ico-close" /></svg>
          </button>
          <div className={classes.wrapper}>
            <Form
              id={id}
              className={classes.form}
              fields={fields}
              submitLabel={strings.send_label}
              sendContactForm={sendContactForm}
              saveConfiguration={saveConfiguration}
              isOnSuccessReset
              onSuccessSend={closeLayer}
              hiddenValues={{
                ricontatto: fields?.find((field) => field.name === 'ricontatto')?.value || '',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectSheet(style)(ModalForm)
