import { useMemo, useState, useEffect, useCallback } from 'react'
import injectSheet from 'react-jss'
import clsx from 'clsx'
import Select from 'react-select'
import style from '../style'

function SelectField({
  classes,
  className,
  classNamePrefix = 'customSelect',
  conditional,
  disabled,
  error,
  label,
  name,
  onBlur,
  options,
  required,
  setFieldValue,
  submitCount,
  touched,
  value,
  values = {},
  withLabel = false,
}) {
  const [optionalIsVisible, setOptionalVisible] = useState(false)

  useEffect(() => {
    if (Object.values(values).some((val) => val === conditional)) {
      setOptionalVisible(true)
    } else {
      setOptionalVisible(false)
    }
  }, [values])

  /*------------------------------
  Mapping Options
  ------------------------------*/
  const getOptions = useMemo(() => {
    return options.map((option) => {
      let strCheck = option.split('|')
      strCheck = strCheck.length > 1 ? strCheck : [option, option]
      return { value: strCheck[1], label: strCheck[0] }
    })
  })

  /*------------------------------
  Handle Change Option
  ------------------------------*/
  const handleChange = useCallback((option) => {
    setFieldValue(name, option.value)
  }, [setFieldValue, name])

  return ( // eslint-disable-line
    <div
      className={clsx({
        [classes.formControl]: true,
        [classes.formControlSelect]: true,
        [classes.formControlOptional]: conditional,
        [classes.formControlOptionalVisible]: optionalIsVisible,
        [classes.formError]: (error && touched) || (error && submitCount >= 1),
        [className]: className,
      })}
    >
      <label className={classes.select}>
        {withLabel && (
          <span className={classes.label}>
            {label}
            {' '}
            {required ? '*' : ''}
          </span>
        )}
        <Select
          isDisabled={disabled}
          inputProps={{
            autoComplete: 'none',
            autoCorrect: 'none',
            spellCheck: 'none',
            autoFocus: 'none',
          }}
          isSearchable={false}
          placeholder={label}
          options={getOptions}
          name={name}
          value={getOptions.find((option) => option.value === value) || null}
          onChange={handleChange}
          onBlur={onBlur}
          className="selectContainer"
          classNamePrefix={classNamePrefix}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        />
      </label>
      <div
        className={clsx({
          [classes.error]: true,
          [classes.visible]: (error && touched) || (error && submitCount >= 1),
        })}
      >
        {error}
      </div>
    </div>
  )
}

export default injectSheet(style)(SelectField)
