import { useEffect, useState, useCallback } from 'react'
import injectSheet from 'react-jss'
import clsx from 'clsx'
import HTMLReactParser from 'react-html-parser'
import DelayLink from '@/components/DelayLink'
import { cleanOrigin } from '@/utils/path'

import style from '../style'

function CheckboxField({
  id,
  required,
  classes,
  label,
  name,
  onBlur,
  type,
  value,
  error,
  touched,
  submitCount,
  setFieldValue,
}) {
  const [transformedContent, setTransformedContent] = useState()

  const transformContent = useCallback(() => {
    const transform = (node, i) => { // eslint-disable-line
      if (node.type === 'tag' && node.name === 'a') {
        return (
          <DelayLink
            key={i.toString()}
            to={cleanOrigin(node.attribs.href)}
          >
            {node.children[0].data}
          </DelayLink>
        )
      }
    }
    setTransformedContent(HTMLReactParser(label, { transform }))
  }, [])

  useEffect(() => {
    transformContent()
  }, [])

  return (
    <div
      className={clsx({
        [classes.formControl]: true,
        [classes.formControlCheckbox]: true,
        [classes.formError]: (error && touched) || (error && submitCount >= 1),
      })}
    >
      <label
        htmlFor={`${name}-${id}`}
        className={classes.checkbox}
      >
        <input
          id={`${name}-${id}`}
          type={type}
          onChange={() => {
            setFieldValue(name, !value)
          }}
          onBlur={onBlur}
          checked={value || false}
        />
        <span className={classes.label}>
          <span>
            {transformedContent}
            {required ? ' *' : ''}
          </span>
        </span>
      </label>
      <div
        className={clsx({
          [classes.error]: true,
          [classes.visible]: (error && touched) || (error && submitCount >= 1),
        })}
      >
        {error}
      </div>
    </div>
  )
}

export default injectSheet(style)(CheckboxField)
